import React from 'react';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import ModalHeader from './modal-header';
import TextExtended from '../../text-extended/text-extended';
import { classes, st } from './confirmation-modal.st.css';
import {
  CONFIRMATION_MODAL,
  PRIMARY_BTN,
  SECONDARY_BTN,
} from '~reviews/common/constants/data-hooks';
import { TranslationKey } from '~reviews/locale-types';
import { Dialog } from '../../_common/dialog/dialog';
import { uniqueId } from 'lodash';
import { Button, ButtonPriority as PRIORITY, ButtonSize } from 'wix-ui-tpa/cssVars';
import { classes as buttonClasses } from './button.st.css';
import classNames from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

type ConfirmationModalProps = (
  | {
      theme: 'confirmation';
      cancelKey: TranslationKey;
    }
  | {
      theme: 'notification';
    }
) & {
  titleKey: TranslationKey;
  messageKey: TranslationKey;
  confirmKey: TranslationKey;
  onConfirm(): void;
  onCancel(): void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const t = useTranslate();
  const { isMobile } = useEnvironment();
  const headerId = React.useMemo(() => uniqueId('header'), []);
  return (
    <Dialog
      data-hook={CONFIRMATION_MODAL}
      className={st(classes.root, {
        isDesktop: !isMobile,
        isMobile,
        theme: props.theme,
      })}
      onClose={props.onCancel}
      wiredToSiteColors={false}
      aria-labelledby={headerId}
    >
      <ModalHeader id={headerId}> {t(props.titleKey)}</ModalHeader>
      <div className={classes.content}>
        <TextExtended fontMapping="siteContent" colorMapping="black">
          {t(props.messageKey)}
        </TextExtended>
      </div>

      <div className={st(classes.buttonGroupWrapper)}>
        {props.theme === 'confirmation' && (
          <div className={classes.buttonWrapper}>
            <Button
              data-hook={SECONDARY_BTN}
              className={classNames(buttonClasses.root, classes.secondaryButton)}
              upgrade={true}
              priority={PRIORITY.secondary}
              size={ButtonSize.small}
              onClick={props.onCancel}
            >
              {t(props.cancelKey)}
            </Button>
          </div>
        )}
        <div className={classes.buttonWrapper}>
          <Button
            data-hook={PRIMARY_BTN}
            className={classNames(buttonClasses.root, classes.primaryButton)}
            upgrade={true}
            priority={PRIORITY.primary}
            size={ButtonSize.small}
            onClick={props.onConfirm}
          >
            {t(props.confirmKey)}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
